import React from "react"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

class DirectorCard extends React.Component {
  constructor() {
    super()
    this.state = {
      modalIsOpen: false,
      selected: 0,
    }
    this.openModal = this.openModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(index) {
    // Add overflow hidden to <html> tag when modal is open
    document.querySelector("html").style.overflow = "hidden"
    this.setState({
      modalIsOpen: true,
      selected: index,
    })
  }

  afterOpenModal() {
    this.subtitle.style.color = "#f00"
  }

  closeModal() {
    // Remove overflow hidden to <html> tag when modal is open
    document.querySelector("html").style.overflow = "unset"
    this.setState({ modalIsOpen: false })
  }

  render() {
    return (
      <>
        {this.props.directors.map((director, index) => (
          <>
            <div className="director column is-one-quarter">
              <div
                className="card is-inline"
                key={index}
                onClick={() => this.openModal(index)}
              >
                <div className="card-image">
                  <figure className="image">
                    <img
                      src={director.cover}
                      style={{ position: `sticky`, top: `8px` }}
                    />
                  </figure>
                  <div className="image-desc">
                    <h3 id="hover-name">{director.name}</h3>
                    <h3>{director.independence}</h3>
                    <h3>{director.role}</h3>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              className="directormodal"
              isOpen={this.state.modalIsOpen}
              onRequestClose={this.closeModal}
              style={{
                overlay: {
                  // Change from absolute to fixed
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(0, 0, 0, 0.25)",
                },
                content: {
                  position: "absolute",
                  // border: '1px solid #ccc',
                  // background: '#ccc',
                  background: "rgba(98,98,98,1.0)",
                  // overflowX: 'auto',
                  WebkitOverflowScrolling: "touch",
                  borderRadius: "4px",
                  outline: "none",
                  padding: "20px",
                },
              }}
            >
              <a
                href="#"
                className="close delete is-large"
                onClick={this.closeModal}
              />
              <div className="container">
                <div className="columns is-mobile is-multiline">
                  <div className="column is-4-desktop is-4-mobile is-4-tablet">
                    <img
                      src={this.props.directors[this.state.selected].cover}
                      style={{ position: `sticky`, top: `25px` }}
                    />
                  </div>
                  <div className="director name column is-3-desktop is-8-mobile is-3-tablet">
                    <div className="columns is-multiline">
                      <div className="column is-full">
                        <h3 className="title is-primary is-paddingless is-marginless">
                          {this.props.directors[this.state.selected].name}
                        </h3>
                      </div>
                      <div className="column is-full">
                        <h5
                          className="subtitle is-size-6 has-text-weight-bold"
                          style={{ marginBottom: "0rem" }}
                        >
                          {
                            this.props.directors[this.state.selected]
                              .independence
                          }
                        </h5>
                        <h5 className="subtitle is-size-6 has-text-weight-bold">
                          {this.props.directors[this.state.selected].role}
                        </h5>
                      </div>
                      {/* <div className="column is-full" >
                      </div> */}
                    </div>
                  </div>

                  <div
                    className="director content column is-4-desktop "
                    style={{
                      overflowY: `scroll`,
                      height: `410px`,
                      marginTop: `20px`,
                    }}
                  >
                    <p>Age: {this.props.directors[this.state.selected].age}</p>

                    <p>
                      Gender: {this.props.directors[this.state.selected].gender}
                    </p>
                    <p>
                      Nationality:{" "}
                      {this.props.directors[this.state.selected].nationality}
                    </p>
                    <p>
                      Date Appointed To Board:{" "}
                      {
                        this.props.directors[this.state.selected]
                          .date_appointed_to_board
                      }
                    </p>
                    <br />
                    <p>Qualification</p>

                    {this.props.directors[
                      this.state.selected
                    ].qualifications.map((qualification, index) => (
                      <ul>
                        <li>{qualification.title}</li>
                      </ul>
                    ))}
                    
                    <br />
                    <p>Working Experience And Occupation</p>
                    {
                    this.props.directors[
                      this.state.selected
                    ].working_experience_and_occupations && 
                    this.props.directors[
                      this.state.selected
                    ].working_experience_and_occupations.map(
                      (experience, index) => (
                        <ul>
                          <li>{experience.title}</li>
                        </ul>
                      )
                    )}
                    <br />
                    <p>Date Appointed To Board:</p>
                   
                    <br />

                    <p>Details Of Any Board Committee Involved:</p>
                    {this.props.directors[
                      this.state.selected
                    ].details_of_board_committee.map((detail, index) => (
                      <ul>
                        <li>{detail.title}</li>
                      </ul>
                    ))}
                    <br />

                    
                      <p>
                        Other Directorship In Public Companies And Listed
                        Issuers:
                      </p>
                      <p>Listed Entity:</p>
                       <ul>
                        {this.props.directors[
                      this.state.selected
                    ].other_directorship.map((entity, index) => {
                      console.log(entity)
                      const listed = (entity.listed_entity) 
                      console.log(listed) 
                     
                      return (
                        
                      listed.map((list) => (<li>{list.title}</li>))
                     
                              )  
                     
                        
                        })} 
                      </ul> 
                    
                    <p></p>      
                      
                      
                     

                    

                    <p>Non Listed Public Companies:</p>
                    <ul>
                     {this.props.directors[
                      this.state.selected
                    ].other_directorship.map((entity, index) => {
                      console.log(entity)
                      const listed = (entity.non_listed_public_companies) 
                      console.log(listed) 
                      return (
                      listed.map((list) => (<li>{list.title}</li>))
                                             )                      
                        
                        })} 
                      </ul>
                    

                    <p>Other Public Companies:</p>
                    <ul>
                      {this.props.directors[
                      this.state.selected
                    ].other_directorship.map((entity, index) => {
                      console.log(entity)
                      const listed = (entity.other_public_companies) 
                      console.log(listed) 
                      return (
                      listed.map((list) => (<li>{list.title}</li>))
                                             )                      
                        
                        })} 
                    </ul>
                    <br />

                    <p>
                      Any Family Relationship With Any Director And/Or Major
                      Shareholder Of The Listed Issuer:
                    </p>
                    <ul>
                      <li>
                        {
                          this.props.directors[this.state.selected]
                            .family_relationship
                        }
                      </li>
                    </ul>
                    <br />

                    <p>Any Conflict Of Interest With The Listed Issuer:</p>
                    <ul>
                      <li>
                        {
                          this.props.directors[this.state.selected]
                            .conflict_of_interest
                        }
                      </li>
                    </ul>
                    <br />

                    <p>
                      List Of Convictions For Offences Within The Past 5 Years
                      And Particulars Of Any Public Sanction Or Penalty Imposed
                      By The Relevant Regulatory Bodies During The Financial
                      Year, If Any:
                    </p>
                    <ul>
                      <li>
                        {this.props.directors[this.state.selected].convictions}
                      </li>
                    </ul>
                    <br />

                    <p>
                      Number Of Board Meetings Attended In The Financial Year:
                    </p>
                    <ul>
                      <li>
                        {
                          this.props.directors[this.state.selected]
                            .board_meetings_attended
                        }
                      </li>
                    </ul>
                    <br />
                      
                        
                  </div>
                </div>
              </div>
            </Modal>
                   
                     
          </>
        ))}
      </>
    )
  }
}

export default DirectorCard
