import React from "react"
// import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import PageWrapper from "../layouts/PageWrapper"
import DirectorCard from "../components/DirectorCard"
// import HomeLogo from '../../components/HomeLogo'
import HomeLogo from "../components/HomeLogo"

export const DirectorPageTemplate = ({
  title,
  meta_description,
  meta_title,
  directors,
}) => (
  <PageWrapper navbarClassName="landing-page">
    <Helmet>
      <title>Board Of Directors - E.A. Technique</title>
    </Helmet>
    <section className="hero is-small" id="director_page-hero">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column">
              <HomeLogo />
              <h1 className="title" id="title-line">
                Board of Directors
              </h1>
              {/* <a href="/management">
                  <div className="title is-5">Management Team</div>
                </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="hero" id="director_page-section">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-multiline is-vcentered">
            <DirectorCard directors={directors} />
          </div>
        </div>
      </div>
    </section>
  </PageWrapper>

)

export const DirectorPage = ({ data }) => {
  console.log(data)

  const { frontmatter } = data.allMarkdownRemark.edges[0].node

  return (
    <DirectorPageTemplate
      title={frontmatter.title}
      meta_title={frontmatter.meta_title}
      meta_description={frontmatter.meta_description}
      directors={frontmatter.directors}
      directorship={frontmatter.directors.other_directorship}
      
    />
  )
}

export default DirectorPage

export const directorPageQuery = graphql`
  query DirectorPage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "director-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            directors {
              name
              independence
              role
              cover
              age
              gender
              nationality
              qualifications {
                title
              }
              working_experience_and_occupations {
                title
              }
              qualifica {
                 title
              }
              
              date_appointed_to_board
              details_of_board_committee {
                title
              }
              other_directorship 
              {                       
               listed_entity {
                title
                }
                non_listed_public_companies {
                title
                }
                other_public_companies {
                title
                }
                }
              family_relationship
              conflict_of_interest
              convictions
              board_meetings_attended
            }
          }
        }
      }
    }
  }
`
